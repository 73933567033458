import React from "react"
import { useStaticQuery, graphql } from "gatsby"

/* Import Global Components */
import Page from "~components/page"
import ReportPreview from "~components/reportPreview"

/* Import Local Styles */
import "./reports.scss"

const ReportsPage = () => {
  const {
    allSanityReport: { nodes: reports },
  } = useStaticQuery(graphql`
    query ReportsQuery {
      allSanityReport(filter: { publishOnWebsite: { eq: true } }) {
        nodes {
          title
          slug
          _rawDescription
          _id
          excerpt
        }
      }
    }
  `)

  return (
    <Page title="reports">
      {reports.map(report => (
        <ReportPreview key={report._id} report={report} />
      ))}
    </Page>
  )
}

export default ReportsPage
