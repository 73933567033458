import React from "react"
import slug from "slug"

/* Import Global Components */
import Link from "~components/link"

/* Import Local Styles */
import "./report-preview.scss"

const ReportPreview = ({ report }) => (
  <div className="report-preview" key={report._id}>
    <Link to={`/reports/${slug(report.title, { lower: true })}`}>
      {report.title}
    </Link>
    <div>
      <p>{report.excerpt}</p>
    </div>
  </div>
)

export default ReportPreview
